.container-serv {
  background-color: var(--black-color);
  color: var(--grey-color);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 200px;
  padding-left: 200px;
}

.container-serv .title {
  font-weight: normal;
  font-size: 30px;
  font-style: italic;
  padding-bottom: 20px;
}

.container-serv .title .icon {
  padding-right: 5px;
}

.container-serv .desc p {
  font-size: 18px;
  padding-bottom: 12px;
}

.container-serv .cards-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .container-serv {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 100px) {
  .container-serv {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }
}
