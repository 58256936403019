@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

:root {
  --black-color: #1c1c1f;
  --blue-black-color: #394054;
  --blue-color: #5da5b3;
  --blue-grey-color: #394054;
  --grey-color: #e0e2e8;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.leaflet-control-container {
  display: none;
}

.leaflet-tile-container img {
  width: 256.5px !important;
  height: 256.5px !important;
}
