@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

:root {
  --black-color: #1c1c1f;
  --blue-black-color: #394054;
  --blue-color: #5da5b3;
  --blue-grey-color: #394054;
  --grey-color: #e0e2e8;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.leaflet-control-container {
  display: none;
}

.leaflet-tile-container img {
  width: 256.5px !important;
  height: 256.5px !important;
}

.container-contact {
  background-color: var(--grey-color);
  color: var(--black-color);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 200px;
  padding-left: 200px;
}

.container-contact .title {
  font-weight: normal;
  font-size: 30px;
  font-style: italic;
  padding-bottom: 20px;
}

.container-contact .title .icon {
  padding-right: 5px;
}

.container-contact .contact-form {
  border: 3px solid var(--black-color);
  margin: 0 auto;
  width: 750px;
  border-radius: 15px;
}

.container-contact .contact-form .contact-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.container-contact .contact-form .contact-btn button {
  cursor: pointer;
  width: 200px;
  height: 35px;
  background-color: transparent;
  border-radius: 5px;
  color: var(--black-color);
  font-size: 16px;
}

.container-contact .contact-form .contact-btn button:hover {
  background-color: var(--blue-color);
}

.container-contact .contact-form .contact-btn .selected {
  background-color: var(--black-color);
  color: var(--grey-color);
  border: var(--grey-color);
}

@media only screen and (max-width: 600px) {
  .container-contact {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .container-contact .contact-form {
    width: 300px;
    border-radius: 15px;
  }

  .container-contact .contact-form .contact-btn button {
    width: 115px;
    height: 35px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .container-contact {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 80px;
    padding-left: 80px;
  }

  .container-contact .contact-form {
    width: 500px;
    border-radius: 15px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 100px) {
  .container-contact {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }
}

.form-container {
  display: flex;
  justify-content: center;
}

.form {
  padding: 20px;
  color: var(--black-color);
}

.form .form-input {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form .form-input p {
  padding-bottom: 5px;
}

.form .form-input input {
  width: 400px;
  height: 30px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: transparent;
}

.form .form-input select {
  width: 100%;
  height: 33px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: transparent;
}

.form .form-input textarea {
  width: 400px;
  height: 90px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: transparent;
}

.form .btn-container .send-form {
  cursor: pointer;
  width: 80px;
  height: 45px;
  background-color: transparent;
  border-radius: 5px;
  background-color: var(--black-color);
  color: var(--grey-color);
  border: var(--grey-color);
}

.form .btn-container .send-form:hover {
  background-color: var(--blue-color);
}

.form .btn-container .send-form .icon {
  font-size: 20px;
}

.form .btn-container {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .form .form-input input {
    width: 260px;
    height: 30px;
    border-radius: 5px;
    padding-left: 3px;
  }

  .form .form-input select {
    width: 100%;
    height: 33px;
    border-radius: 5px;
    padding-left: 3px;
  }

  .form .form-input textarea {
    width: 260px;
    height: 90px;
    border-radius: 5px;
    padding-left: 3px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .form .form-input input {
    width: 300px;
    height: 30px;
    border-radius: 5px;
    padding-left: 3px;
  }

  .form .form-input select {
    width: 100%;
    height: 33px;
    border-radius: 5px;
    padding-left: 3px;
  }

  .form .form-input textarea {
    width: 300px;
    height: 90px;
    border-radius: 5px;
    padding-left: 3px;
  }
}

footer {
  background-color: var(--black-color);
  color: var(--grey-color);
  font-size: 18px;
}

footer .container-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 75px;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
}

footer .divider {
  height: 75px;
  border-left: 2px solid var(--grey-color);
  margin-left: -1px;
}

footer .address {
  width: 250px;
  text-align: end;
}

footer .contact {
  width: 250px;
  display: flex;
  flex-direction: column;
}

footer .contact a {
  text-decoration: none;
  color: var(--grey-color);
}

footer .contact a span {
  font-family: sans-serif;
}

footer .social {
  display: flex;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
  padding-bottom: 15px;
}

footer .social .tab-text {
  cursor: pointer;
  text-decoration: none;
  font-size: 25px;
  color: var(--grey-color);
}

footer .social .tab-text:hover {
  color: var(--blue-color);
}

footer .legal {
  text-align: center;
  padding-bottom: 15px;
  font-size: 15px;
  font-style: italic;
}

@media only screen and (max-width: 600px) {
  footer {
    font-size: 12px;
  }

  footer .container-footer {
    height: 45px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  footer .divider {
    height: 45px;
  }

  footer .social .tab-text {
    font-size: 20px;
  }
}

.header {
  height: 40px;
  background-color: var(--grey-color);
  color: var(--black-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header .header-text {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
}

.header a {
  text-decoration: none;
  color: var(--black-color);
}

.header a .icon {
  padding-right: 3px;
}

.header a span {
  font-family: sans-serif;
}

/* Navbar CSS */
.navbar {
  background-color: var(--black-color);
  color: var(--grey-color);
  height: 100px;
}

.navbar ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 55px 0 70px;
}

.navbar ul .logo p {
  font-size: 24px;
  cursor: pointer;
}

.navbar ul .tab {
  display: flex;
  flex-direction: row;
}

.navbar ul .tab li {
  padding: 0 15px;
}

.navbar ul .tab li .tab-text .icon {
  padding-right: 5px;
}

.navbar ul .tab li .tab-text {
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  color: var(--grey-color);
}

.navbar ul .tab li .tab-text:hover {
  color: var(--blue-color);
}

.navbar ul .tab li .social-media {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  .navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;
  }

  .navbar ul .tab {
    display: none;
  }

  .navbar ul .logo p {
    font-size: 24px;
    cursor: pointer;
  }

  .hero-banner .hero-text .hero-title {
    font-size: 25px !important;
    padding-bottom: 15px;
  }

  .hero-banner .hero-text .hero-subtitle {
    font-size: 18px !important;
  }

  .header .header-text {
    font-size: 11px;
  }

  .hero-banner .hero-text {
    width: 92%;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .navbar ul .logo p {
    font-size: 20px;
    cursor: pointer;
  }

  .navbar ul .tab li .tab-text {
    font-size: 15px;
    cursor: pointer;
  }
}

/* Hero CSS */
.hero-banner .hero-background {
  background-image: url(/static/media/background-light.669fc3bc.jpg);
  height: calc(100vh - 140px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-banner .hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.hero-banner .hero-text .hero-title {
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
}

.hero-banner .hero-text .hero-separator {
  border: none;
  border-bottom: 3px solid var(--black-color);
  width: 35%;
  margin: 0 auto;
}

.hero-banner .hero-text .hero-subtitle {
  font-size: 25px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.hero-banner .hero-text .button-container {
  display: flex;
  justify-content: center;
}

.hero-banner .hero-text .button-container .hero-button {
  width: 200px;
  height: 60px;
  font-size: 16px;
  background-color: var(--black-color);
  color: var(--grey-color);
  padding: 10px 25px;
  border-radius: 5px;
  border: 1px solid var(--grey-color);
  cursor: pointer;
}

.hero-banner .hero-text .button-container .hero-button:hover {
  background-color: var(--blue-color);
}

.container-pres {
  background-color: var(--grey-color);
  color: var(--black-color);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 200px;
  padding-left: 200px;
}

.container-pres .title {
  font-weight: normal;
  font-size: 30px;
  font-style: italic;
  padding-bottom: 20px;
}

.container-pres .title .icon {
  padding-right: 5px;
}

.container-pres .desc p {
  font-size: 18px;
  padding-bottom: 12px;
}

.container-pres .flex-pres {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 25px;
  gap: 25px;
}

.map {
  height: 400px;
  width: 400px;
}

@media only screen and (max-width: 600px) {
  .container-pres .flex-pres {
    flex-direction: column;
  }

  .container-pres {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .map {
    height: 300px;
    width: 300px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .container-pres .flex-pres {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 100px) {
  .container-pres {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
    padding-left: 30px;
  }
}

.container-serv {
  background-color: var(--black-color);
  color: var(--grey-color);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 200px;
  padding-left: 200px;
}

.container-serv .title {
  font-weight: normal;
  font-size: 30px;
  font-style: italic;
  padding-bottom: 20px;
}

.container-serv .title .icon {
  padding-right: 5px;
}

.container-serv .desc p {
  font-size: 18px;
  padding-bottom: 12px;
}

.container-serv .cards-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .container-serv {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 100px) {
  .container-serv {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }
}

.card {
  height: 400px;
  width: 300px;
  background-color: var(--grey-color);
  color: var(--black-color);
  border-radius: 10px;
  overflow: scroll;
}

.card .card-title {
  text-align: center;
}

.card .card-img {
  padding: 10px;
  width: calc(100% - 20px);
  margin: 0 auto;
  border-radius: 20px;
  object-fit: cover;
  height: 190px;
}

.card .card-text {
  padding: 10px;
}

