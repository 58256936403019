.card {
  height: 400px;
  width: 300px;
  background-color: var(--grey-color);
  color: var(--black-color);
  border-radius: 10px;
  overflow: scroll;
}

.card .card-title {
  text-align: center;
}

.card .card-img {
  padding: 10px;
  width: calc(100% - 20px);
  margin: 0 auto;
  border-radius: 20px;
  object-fit: cover;
  height: 190px;
}

.card .card-text {
  padding: 10px;
}
