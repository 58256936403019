footer {
  background-color: var(--black-color);
  color: var(--grey-color);
  font-size: 18px;
}

footer .container-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 75px;
  align-items: center;
  gap: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
}

footer .divider {
  height: 75px;
  border-left: 2px solid var(--grey-color);
  margin-left: -1px;
}

footer .address {
  width: 250px;
  text-align: end;
}

footer .contact {
  width: 250px;
  display: flex;
  flex-direction: column;
}

footer .contact a {
  text-decoration: none;
  color: var(--grey-color);
}

footer .contact a span {
  font-family: sans-serif;
}

footer .social {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 15px;
}

footer .social .tab-text {
  cursor: pointer;
  text-decoration: none;
  font-size: 25px;
  color: var(--grey-color);
}

footer .social .tab-text:hover {
  color: var(--blue-color);
}

footer .legal {
  text-align: center;
  padding-bottom: 15px;
  font-size: 15px;
  font-style: italic;
}

@media only screen and (max-width: 600px) {
  footer {
    font-size: 12px;
  }

  footer .container-footer {
    height: 45px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  footer .divider {
    height: 45px;
  }

  footer .social .tab-text {
    font-size: 20px;
  }
}
