.container-contact {
  background-color: var(--grey-color);
  color: var(--black-color);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 200px;
  padding-left: 200px;
}

.container-contact .title {
  font-weight: normal;
  font-size: 30px;
  font-style: italic;
  padding-bottom: 20px;
}

.container-contact .title .icon {
  padding-right: 5px;
}

.container-contact .contact-form {
  border: 3px solid var(--black-color);
  margin: 0 auto;
  width: 750px;
  border-radius: 15px;
}

.container-contact .contact-form .contact-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  column-gap: 10px;
}

.container-contact .contact-form .contact-btn button {
  cursor: pointer;
  width: 200px;
  height: 35px;
  background-color: transparent;
  border-radius: 5px;
  color: var(--black-color);
  font-size: 16px;
}

.container-contact .contact-form .contact-btn button:hover {
  background-color: var(--blue-color);
}

.container-contact .contact-form .contact-btn .selected {
  background-color: var(--black-color);
  color: var(--grey-color);
  border: var(--grey-color);
}

@media only screen and (max-width: 600px) {
  .container-contact {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .container-contact .contact-form {
    width: 300px;
    border-radius: 15px;
  }

  .container-contact .contact-form .contact-btn button {
    width: 115px;
    height: 35px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .container-contact {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 80px;
    padding-left: 80px;
  }

  .container-contact .contact-form {
    width: 500px;
    border-radius: 15px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 100px) {
  .container-contact {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }
}
