.header {
  height: 40px;
  background-color: var(--grey-color);
  color: var(--black-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header .header-text {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
}

.header a {
  text-decoration: none;
  color: var(--black-color);
}

.header a .icon {
  padding-right: 3px;
}

.header a span {
  font-family: sans-serif;
}

/* Navbar CSS */
.navbar {
  background-color: var(--black-color);
  color: var(--grey-color);
  height: 100px;
}

.navbar ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 55px 0 70px;
}

.navbar ul .logo p {
  font-size: 24px;
  cursor: pointer;
}

.navbar ul .tab {
  display: flex;
  flex-direction: row;
}

.navbar ul .tab li {
  padding: 0 15px;
}

.navbar ul .tab li .tab-text .icon {
  padding-right: 5px;
}

.navbar ul .tab li .tab-text {
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  color: var(--grey-color);
}

.navbar ul .tab li .tab-text:hover {
  color: var(--blue-color);
}

.navbar ul .tab li .social-media {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  .navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;
  }

  .navbar ul .tab {
    display: none;
  }

  .navbar ul .logo p {
    font-size: 24px;
    cursor: pointer;
  }

  .hero-banner .hero-text .hero-title {
    font-size: 25px !important;
    padding-bottom: 15px;
  }

  .hero-banner .hero-text .hero-subtitle {
    font-size: 18px !important;
  }

  .header .header-text {
    font-size: 11px;
  }

  .hero-banner .hero-text {
    width: 92%;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .navbar ul .logo p {
    font-size: 20px;
    cursor: pointer;
  }

  .navbar ul .tab li .tab-text {
    font-size: 15px;
    cursor: pointer;
  }
}

/* Hero CSS */
.hero-banner .hero-background {
  background-image: url('../assets/images/background-light.jpg');
  height: calc(100vh - 140px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-banner .hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-banner .hero-text .hero-title {
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
}

.hero-banner .hero-text .hero-separator {
  border: none;
  border-bottom: 3px solid var(--black-color);
  width: 35%;
  margin: 0 auto;
}

.hero-banner .hero-text .hero-subtitle {
  font-size: 25px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.hero-banner .hero-text .button-container {
  display: flex;
  justify-content: center;
}

.hero-banner .hero-text .button-container .hero-button {
  width: 200px;
  height: 60px;
  font-size: 16px;
  background-color: var(--black-color);
  color: var(--grey-color);
  padding: 10px 25px;
  border-radius: 5px;
  border: 1px solid var(--grey-color);
  cursor: pointer;
}

.hero-banner .hero-text .button-container .hero-button:hover {
  background-color: var(--blue-color);
}
