.form-container {
  display: flex;
  justify-content: center;
}

.form {
  padding: 20px;
  color: var(--black-color);
}

.form .form-input {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form .form-input p {
  padding-bottom: 5px;
}

.form .form-input input {
  width: 400px;
  height: 30px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: transparent;
}

.form .form-input select {
  width: 100%;
  height: 33px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: transparent;
}

.form .form-input textarea {
  width: 400px;
  height: 90px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: transparent;
}

.form .btn-container .send-form {
  cursor: pointer;
  width: 80px;
  height: 45px;
  background-color: transparent;
  border-radius: 5px;
  background-color: var(--black-color);
  color: var(--grey-color);
  border: var(--grey-color);
}

.form .btn-container .send-form:hover {
  background-color: var(--blue-color);
}

.form .btn-container .send-form .icon {
  font-size: 20px;
}

.form .btn-container {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .form .form-input input {
    width: 260px;
    height: 30px;
    border-radius: 5px;
    padding-left: 3px;
  }

  .form .form-input select {
    width: 100%;
    height: 33px;
    border-radius: 5px;
    padding-left: 3px;
  }

  .form .form-input textarea {
    width: 260px;
    height: 90px;
    border-radius: 5px;
    padding-left: 3px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .form .form-input input {
    width: 300px;
    height: 30px;
    border-radius: 5px;
    padding-left: 3px;
  }

  .form .form-input select {
    width: 100%;
    height: 33px;
    border-radius: 5px;
    padding-left: 3px;
  }

  .form .form-input textarea {
    width: 300px;
    height: 90px;
    border-radius: 5px;
    padding-left: 3px;
  }
}
