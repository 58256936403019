.container-pres {
  background-color: var(--grey-color);
  color: var(--black-color);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 200px;
  padding-left: 200px;
}

.container-pres .title {
  font-weight: normal;
  font-size: 30px;
  font-style: italic;
  padding-bottom: 20px;
}

.container-pres .title .icon {
  padding-right: 5px;
}

.container-pres .desc p {
  font-size: 18px;
  padding-bottom: 12px;
}

.container-pres .flex-pres {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}

.map {
  height: 400px;
  width: 400px;
}

@media only screen and (max-width: 600px) {
  .container-pres .flex-pres {
    flex-direction: column;
  }

  .container-pres {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .map {
    height: 300px;
    width: 300px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
  .container-pres .flex-pres {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 100px) {
  .container-pres {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
    padding-left: 30px;
  }
}
